import {useCallback, useEffect, useRef, useState} from "react";
import {gsap} from "gsap/dist/gsap";
import Container from "./Container";
import Circle from "./Circle";

import imageInner01 from "../public/images/circle/top_01.jpg";
import imageInner02 from "../public/images/circle/top_02.jpg";
import imageInner03 from "../public/images/circle/top_03.jpg";
import imageInner04 from "../public/images/circle/top_04.jpg";
import imageInner05 from "../public/images/circle/top_05.jpg";
import imageInner06 from "../public/images/circle/top_06.jpg";
import imageInner07 from "../public/images/circle/top_07.jpg";
import imageInner08 from "../public/images/circle/top_08.jpg";

import imageOuter01 from "../public/images/circle/bottom_01.jpg";
import imageOuter02 from "../public/images/circle/bottom_02.jpg";
import imageOuter03 from "../public/images/circle/bottom_03.jpg";
import imageOuter04 from "../public/images/circle/bottom_04.jpg";
import imageOuter05 from "../public/images/circle/bottom_05.jpg";
import imageOuter06 from "../public/images/circle/bottom_06.jpg";
import imageOuter07 from "../public/images/circle/bottom_07.jpg";
import imageOuter08 from "../public/images/circle/bottom_08.jpg";

const photosInner = [
    imageInner01,
    imageOuter03,
    imageInner02,
    imageInner04,
    imageInner08,
    imageInner06,
    imageOuter05,
    imageInner05,
];

const photosOuter = [
    imageOuter01,
    imageOuter02,
    imageInner07,
    imageOuter04,
    imageInner03,
    imageOuter06,
    imageOuter07,
    imageOuter08
];

export default function CirclePhotos({className = '', children, ...rest}) {
    const containerRef = useRef(null);
    const numberRef = useRef(null);
    const [circleSize, setCircleSize] = useState(0);
    const [rotation, setRotation] = useState(0);

    const recalculateCircleSize = useCallback(() => {
        if (numberRef.current) {
            const numberWidth = numberRef.current.clientWidth;
            const numberHeight = numberRef.current.clientHeight;
            const numberSize = Math.max(numberWidth, numberHeight) + 500;

            setCircleSize(numberSize);
        }
    }, [numberRef]);

    useEffect(() => {
        if (numberRef.current) recalculateCircleSize();

        window.addEventListener("resize", recalculateCircleSize);

        return () => {
            window.removeEventListener("resize", recalculateCircleSize);
        };
    }, [recalculateCircleSize]);

    useEffect(() => {
        const mm = gsap.matchMedia();

        mm.add({
            noReduceMotion: "(prefers-reduced-motion: no-preference)",
        }, (context) => {
            // let {noReduceMotion} = context.conditions;

            gsap.to(containerRef.current, {
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top bottom",
                    scrub: 1.2,
                    markers: false,
                },
                onUpdate: function () {
                    const progress = this.progress();
                    setRotation(progress * 120);
                }
            })
        });

        return () => mm.revert();
    }, []);

    return (
        <section
            className={`text-dark py-[60vh] ${className}`}
            ref={containerRef}
            {...rest}
        >
            <div className="relative min-h-screen-no-nav flex"
                 style={{
                     height: `${circleSize}px`
                 }}
            >
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 will-change-transform"
                     style={{
                         width: `${circleSize}px`,
                         height: `${circleSize}px`
                     }}
                >
                    <Circle circleSize={circleSize}
                            photos={photosOuter}
                            className="w-[150%] h-[150%] blur-[15px]"
                            rotation={rotation}
                            rotationMultiplier={-1}
                    />
                    <Circle circleSize={circleSize}
                            photos={photosInner}
                            className="w-full h-full"
                            rotation={rotation}
                    />
                </div>

                <Container className="relative text-center m-auto">
                    <header>
                        <h2>
                            <div className="text-2xl lg:text-4xl font-medium">
                                We already produced more than
                            </div>

                            <div className="my-12">
                                <div
                                    className="inline-flex text-[180px] lg:text-[400px] leading-[0.8] font-semibold text-gradient"
                                    ref={numberRef}
                                >
                                    120
                                </div>
                            </div>

                            <div className="text-2xl lg:text-4xl font-medium max-w-[26ch] mx-auto">
                                hours of premium educational footage. For us, and our clients
                            </div>
                        </h2>
                    </header>
                </Container>
            </div>
        </section>
    )
}