import Head from 'next/head'
import {generateTitle} from "../helpers/seo";
import Intro from "../components/Intro";
import CirclePhotos from "../components/CirclePhotos";
import SelectingPeople from "../components/SelectingPeople";
import Gallery from "../components/Gallery";
import Preview from "../components/Preview";
import MainClaims from "../components/MainClaims";
import {useEffect, useRef} from "react";
import {gsap} from "gsap/dist/gsap";
import SoundsExpensive from "../components/SoundsExpensive";
import Contact from "../components/Contact";

function updateColors(progress, direction = 'to-dark') {
    const html = document.querySelector('html');
    const colors = direction === 'to-dark' ? ['#F4F4F4', '#161616'] : ['#161616', '#F4F4F4'];

    const color = gsap.utils.interpolate(colors, progress).toString();

    if (color[0] === 'r') {
        const rgbaArray = color.substring(color.indexOf('(') + 1, color.lastIndexOf(')')).split(',');

        html.style.setProperty('--bg-r', rgbaArray[0]);
        html.style.setProperty('--bg-g', rgbaArray[1]);
        html.style.setProperty('--bg-b', rgbaArray[2]);
    }

    if (direction === 'to-dark') {
        progress > 0.5 ? html.classList.remove('black-nav') : html.classList.add('black-nav');
    } else {
        progress > 0.5 ? html.classList.add('black-nav') : html.classList.remove('black-nav');
    }

    html.style.backgroundColor = color;
}

export default function Home() {
    const containerRef = useRef(null);
    const whiteTriggerRef = useRef(null);
    const blackTriggerRef = useRef(null);

    useEffect(() => {
        let ctx = gsap.context(() => {
            let enteredWhite = false;

            gsap.to(whiteTriggerRef.current, {
                scrollTrigger: {
                    trigger: whiteTriggerRef.current,
                    start: "top center",
                    end: "+=200",
                    pin: false,
                    // pinSpacing: true,
                    // toggleActions: "play none none reverse",
                    scrub: 1.2,
                    markers: false,
                },
                onUpdate: function () {
                    const progress = this.progress();
                    enteredWhite = progress > 0;
                    updateColors(progress, 'to-light');
                },
            });

            gsap.to(blackTriggerRef.current, {
                scrollTrigger: {
                    trigger: blackTriggerRef.current,
                    start: "top center",
                    end: "+=200",
                    pin: false,
                    // pinSpacing: true,
                    // toggleActions: "play none none reverse",
                    scrub: 1.2,
                    markers: false,
                },
                onUpdate: function () {
                    if (enteredWhite) {
                        const progress = this.progress();
                        updateColors(progress, 'to-dark');
                    }
                },
            })
        }, containerRef);

        return () => ctx.revert();
    }, []);

    return (
        <div ref={containerRef}>
            <Head>
                <title>{generateTitle()}</title>
            </Head>

            <Intro/>

            <MainClaims/>

            <div className="w-full h-px mb-[200px]" ref={whiteTriggerRef}/>

            <CirclePhotos/>

            <SelectingPeople className="mt-52"/>

            <Gallery/>

            <Preview/>

            <div className="w-full h-px mb-[50vh]" ref={blackTriggerRef}/>

            <SoundsExpensive/>

            <Contact/>
        </div>
    )
}
