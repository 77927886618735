import SvgFacebook from "./SvgFacebook";
import SvgInstagram from "./SvgInstagram";
import SvgTwitter from "./SvgTwitter";
import SvgWhatsApp from "./SvgWhatsApp";

export default function SvgSocialIcon({service, ...rest}) {
    switch (service) {
        case 'facebook':
            return <SvgFacebook {...rest}/>
        case 'instagram':
            return <SvgInstagram {...rest}/>
        case 'twitter':
            return <SvgTwitter {...rest}/>
        case 'whatsapp':
            return <SvgWhatsApp {...rest}/>
        default:
            return <div className="text-red">{service} not found</div>
    }
}