module.exports = {
    themeColor: "#000000",
    baseUrl: "https://www.pureducation.com",
    titleSuffix: "PUREDU - Own Your Craft",
    titleSeparator: "|",
    og: {
        title: "PUREDU",
        siteName: "PUREDU",
        url: "https://www.pureducation.com",
        description: "Expert Video Course Creation & Custom Platform Solutions - Retain 100% Earnings. Elevate your expertise with professional video production and a tailor-made web platform designed exclusively for your online course, empowering you to maximize your earnings and impact.",
        type: "website",
        image: "images/og_image.png"
    }
};