import {gsap} from "gsap/dist/gsap";
import {useEffect, useRef, useState} from "react";
import Image from "next/image";
import introImage from "../public/videos/puredu_loop.png";

export default function Intro({className = '', children, ...rest}) {
    const imageRef = useRef(null);
    const videoRef = useRef(null);
    const titleRef = useRef(null);
    const containerRef = useRef(null);
    const [playing, setPlaying] = useState(false);



    useEffect(() => {
        const mm = gsap.matchMedia();

        mm.add({
            noReduceMotion: "(prefers-reduced-motion: no-preference)",
        }, (context) => {
            // let {noReduceMotion} = context.conditions;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top top",
                    end: "+=50%",
                    pin: containerRef.current,
                    // pinSpacing: true,
                    // toggleActions: "play none none reverse",
                    scrub: 1.2,
                    markers: false,
                    onUpdate: ({progress}) => {
                        // if progress is higher than 0.1 and the video is not playing, play it
                        if (progress >= 0.05 && videoRef.current.paused) {
                            setPlaying(true);
                        }
                    }
                }
            })

            tl.fromTo(imageRef.current, {
                width: "100vw",
                maxWidth: "1200px",
            }, {
                maxWidth: "100vw",
                height: "100vh",
            }, 0);

            tl.to(titleRef.current, {
                opacity: 0,
                y: -200
            }, 0);
        });

        return () => mm.revert();
    }, []);





    useEffect(() => {
        if (playing) {
            videoRef.current.play();
        }
    }, [playing]);

    return (
        <section className={`w-full min-h-screen flex flex-col ${className}`}
                 ref={containerRef}
                 {...rest}
        >
            <div className="relative h-full flex-1">
                <header className="min-h-[400px] sm:min-h-[50vh] flex flex-col">
                    <h1 className="m-auto text-5xl lg:text-7xl font-semibold flex flex-col items-center text-center"
                        ref={titleRef}
                    >
                        <span>Make your education</span>
                        <span className="text-gradient">extraordinary.</span>
                    </h1>
                </header>

                <div className="absolute left-0 w-full bottom-0">
                    <div className="relative left-1/2 -translate-x-1/2 h-[50vh] sm:min-h-[412px] lg:flex-1" ref={imageRef}>
                        <Image src={introImage}
                               fill={true}
                               className={`z-10 pointer-events-none transition-all duration-200 object-cover ${playing ? 'motion-safe:opacity-0' : ''}`}
                               alt="PUREDU - Make your education extraordinary."
                        />
                        <video ref={videoRef} muted={true} playsInline={true}
                               loop={true}
                               className="absolute top-0 left-0 w-full h-full object-cover">
                            <source src="/videos/puredu_loop.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}