import Container from "./Container";
import Image from "next/image";
import imageThad from "../public/images/thad.jpg";
import {gsap} from "gsap/dist/gsap";
import {useEffect, useRef} from "react";

export default function SelectingPeople({className = '', children, ...rest}) {
    const containerRef = useRef(null);
    const slideInRef = useRef([]);


    useEffect(() => {
        const mm = gsap.matchMedia();

        mm.add({
            noReduceMotion: "(prefers-reduced-motion: no-preference)",
        }, (context) => {
            // let {noReduceMotion} = context.conditions;

            gsap.utils.toArray(slideInRef.current).forEach((elem, i) => {
                gsap.fromTo(elem, {
                    opacity: 0,
                    y: -50,
                    clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
                }, {
                    opacity: 1,
                    y: 0,
                    clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                    scrollTrigger: {
                        trigger: elem,
                        start: "center bottom-=200",
                        end: "+=200",
                        // pin: containerRef.current,
                        // pinSpacing: true,
                        // toggleActions: "play none none reverse",
                        scrub: 2,
                        markers: false,
                    }
                }, i * 0.5);
            });
        });

        return () => mm.revert();
    }, []);

    return (
        <section className={`text-dark ${className}`}
                 ref={containerRef}
                 {...rest}
        >
            <Container className="py-32">
                <header className="mb-20">
                    <h2 className="text-6xl lg:text-8xl flex flex-col font-medium"
                        ref={(el) => slideInRef.current[0] = el}
                    >
                        Filming world-wide
                        <b className="font-medium text-gradient">for the most talented.</b>
                    </h2>
                </header>

                <div className="flex flex-col md:flex-row items-start">
                    <div className="flex-1">
                        <p className="text-5xl font-medium mb-11"
                           ref={(el) => slideInRef.current[1] = el}
                        >
                            We carefully select the people we work with and the people we work for.
                        </p>

                        <p className="text-2xl font-medium"
                           ref={(el) => slideInRef.current[2] = el}
                        >
                            We’re not doing it just for the money. Our mission is to share the knowledge of the most
                            talented individuals and coat it in the best experience possible. At the heart of our work
                            is a passion for creativity and innovation. We stay up to date with the latest technology
                            and techniques, and constantly push ourselves to explore new ways to tell stories and share
                            knowledge. Our team of experienced professionals brings a wealth of knowledge and expertise
                            to every project, ensuring that each piece we produce is not only visually stunning, but
                            also thought-provoking and engaging.
                        </p>
                    </div>

                    <div className="flex-1 max-w-[440px] mt-24 md:ml-24 md:mt-0"
                         ref={(el) => slideInRef.current[3] = el}
                    >
                        <figure className="relative block">
                            <Image src={imageThad}
                                   alt="Meet Thaddeus. He did hair for Drake, Big Sean, or J. Balvin. He also works with us."
                            />
                            <figcaption className="block mt-12 font-medium text-2xl"
                                        ref={(el) => slideInRef.current[4] = el}
                            >
                                Meet Thaddeus. He did hair for Drake, Big Sean, or J. Balvin. He also works with us.
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </Container>
        </section>
    )
}