export default function SvgInstagram(props) {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5 0H7.5C3.3585 0 0 3.3585 0 7.5V16.5C0 20.6415 3.3585 24 7.5 24H16.5C20.6415 24 24 20.6415 24 16.5V7.5C24 3.3585 20.6415 0 16.5 0ZM21.75 16.5C21.75 19.395 19.395 21.75 16.5 21.75H7.5C4.605 21.75 2.25 19.395 2.25 16.5V7.5C2.25 4.605 4.605 2.25 7.5 2.25H16.5C19.395 2.25 21.75 4.605 21.75 7.5V16.5Z"
                fill="currentColor"/>
            <path
                d="M12 6C8.6865 6 6 8.6865 6 12C6 15.3135 8.6865 18 12 18C15.3135 18 18 15.3135 18 12C18 8.6865 15.3135 6 12 6ZM12 15.75C9.933 15.75 8.25 14.067 8.25 12C8.25 9.9315 9.933 8.25 12 8.25C14.067 8.25 15.75 9.9315 15.75 12C15.75 14.067 14.067 15.75 12 15.75Z"
                fill="currentColor"/>
            <path
                d="M18.45 6.34949C18.8916 6.34949 19.2495 5.99154 19.2495 5.54999C19.2495 5.10844 18.8916 4.75049 18.45 4.75049C18.0085 4.75049 17.6505 5.10844 17.6505 5.54999C17.6505 5.99154 18.0085 6.34949 18.45 6.34949Z"
                fill="currentColor"/>
        </svg>
    )
}