import Container from "./Container";
import Claim from "./Claim";

export default function SoundsExpensive({className = '', children, ...rest}) {
    return (
        <section className={className} {...rest}>
            <Container className="py-32">
                <Claim className="flex flex-col">
                    Sounds expensive, right?
                    <b>You’d be surprised.</b>
                </Claim>
            </Container>
        </section>
    )
}