import Container from "./Container";
import Button from "./Button";
import {generateScheduleMailto} from "../helpers/strings";
import {socialLinks} from "../helpers/links";
import SvgSocialIcon from "../svg/SvgSocialIcon";

export default function Contact({className = '', children, ...rest}) {
    return (
        <section id="contact" className={className} {...rest}>
            <Container className="flex flex-col md:flex-row py-32">
                <div className="flex-1 mb-32 md:mb-0 md:mr-8">
                    <header className="mb-11">
                        <h2 className="font-medium flex flex-col text-7xl md:text-8xl">
                            Let’s start
                            <b className="text-gradient font-medium">creating together.</b>
                        </h2>
                    </header>

                    <div>
                        <Button href={generateScheduleMailto()}
                                size="3xl"
                                className="w-full sm:w-auto"
                        >
                            Drop us a line
                        </Button>
                    </div>
                </div>

                <div className="flex md:flex-col">
                    {Object.keys(socialLinks).map((slug) => {
                        return (
                            <a href={socialLinks[slug].href}
                               key={`SocialLink: ${slug}`}
                               className="inline-flex w-6 text-dark-green mr-10 md:mr-0 md:mb-6 last-of-type:mb-0 mouse-hover:text-white transition-colors duration-300"
                               rel="nofollow noreferrer"
                               target="_blank"
                            >
                                <SvgSocialIcon width="100%" service={slug}/>
                            </a>
                        )
                    })}
                </div>
            </Container>
        </section>
    )
}