import {useEffect, useRef, useState} from "react";
import Image from "next/image";

export default function Circle({photos, circleSize, rotation = 0, rotationMultiplier = 1, className = '', ...rest}) {
    const [radius, setRadius] = useState(0);

    const circleContainerRef = useRef(null);

    useEffect(() => {
        const updateRadius = () => {
            const containerHeight = circleContainerRef.current.clientHeight;
            const calculatedRadius = containerHeight * 0.4; // Set the radius as a percentage of the container height
            setRadius(calculatedRadius);
        };

        updateRadius();

        window.addEventListener("resize", updateRadius);

        return () => {
            window.removeEventListener("resize", updateRadius);
        };
    }, [circleSize]);

    return (
        <div
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full will-change-transform ${className}`}
            ref={circleContainerRef}
        >
            <div className="absolute top-0 left-0 w-full h-full rounded-full will-change-transform" style={{
                transform: `rotate(${rotation * rotationMultiplier}deg)`
            }}>
                {photos.map((photo, key) => {
                        const angle = key * 45;
                        return (
                            <div className="circle-photo"
                                 key={`Photo: ${key}`}
                                 style={{
                                     '--angle': `${angle}deg`,
                                     '--radius': `${radius}px`
                                 }}
                            >
                                <Image src={photo}
                                       fill={true}
                                       alt=""
                                       className="object-cover"
                                />
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}