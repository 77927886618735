import Container from "./Container";
import {useCallback, useEffect, useRef, useState} from "react";
import courseImage from "../public/images/preview/course.png";
import playerImage from "../public/images/preview/video_player.png";
import onboardingImage from "../public/images/preview/onboarding.png";
import checkoutImage from "../public/images/preview/checkout.png";
import Image from "next/image";
import {gsap} from "gsap/dist/gsap";

function Tablet({slides, activeTab}) {
    return (
        <div
            className="w-full max-w-[986px] aspect-[986/756] bg-dark rounded-[20px] md:rounded-[50px] mx-auto p-5 md:p-10 shadow-tablet"
        >
            <div className="flex flex-nowrap rounded-[10px] md:rounded-[40px] overflow-hidden bg-dark">
                {slides.map((slide, key) => {
                    return (
                        <div
                            className="w-full h-full border border-dark shrink-0 flex motion-safe:transition-transform motion-safe:duration-1000 motion-safe:ease-in-out rounded-[40px] overflow-hidden"
                            key={`Slide: ${key}`}
                            style={{
                                transform: `translateX(-${activeTab * 100}%)`,
                            }}
                        >
                            <Image src={slide.image}
                                   alt={slide.label}
                                   priority
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const slides = [
    {
        label: 'Course Page',
        image: courseImage
    },
    {
        label: 'Video Player',
        image: playerImage
    },
    {
        label: 'Onboarding',
        image: onboardingImage
    },
    {
        label: 'Checkout',
        image: checkoutImage
    },
]

export default function Preview({className = '', children, ...rest}) {
    const containerRef = useRef(null);
    const buttonRefs = useRef([]);
    const animateRefs = useRef([]);
    const [activeTab, setActiveTab] = useState(0);
    const [lineStyle, setLineStyle] = useState({});
    const [navOffset, setNavOffset] = useState(0);

    const handleLineSize = useCallback(() => {
        const {offsetWidth, offsetLeft} = buttonRefs.current[activeTab];

        setLineStyle({
            left: `${offsetLeft}px`,
            width: `${offsetWidth}px`
        });
    }, [activeTab]);


    useEffect(() => {
        const mm = gsap.matchMedia();

        mm.add({
            noReduceMotion: "(prefers-reduced-motion: no-preference)",
        }, (context) => {
            // let {noReduceMotion} = context.conditions;

            animateRefs.current.forEach((elem, key) => {
                gsap.fromTo(elem, {
                    y: 100,
                    autoAlpha: 0,
                }, {
                    // y: () => (claimHeight - titleHeight) * -1,
                    y: 0,
                    autoAlpha: 1,
                    scrollTrigger: {
                        trigger: elem,
                        start: 'top bottom',
                        end: "+=500",
                        // end: () => '+=' + claimHeight,
                        scrub: true,
                        markers: false,
                    },
                });
            });
        });

        return () => mm.revert();
    }, []);

    useEffect(() => {
        if (activeTab === 0 || window.innerWidth >= 768) {
            setNavOffset(0);
            return;
        }

        const button = buttonRefs.current[activeTab];

        const style = button.currentStyle || window.getComputedStyle(button);
        const left = button.offsetLeft;
        const width = button.offsetWidth;
        const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
        const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

        const fullWidth = width + margin + padding + border;

        setNavOffset(left - (containerRef.current.offsetWidth / 2) + (fullWidth / 2));

        handleLineSize();

    }, [activeTab, handleLineSize]);

    useEffect(() => {

        handleLineSize();
        window.addEventListener('resize', handleLineSize);

        return () => window.removeEventListener('resize', handleLineSize);

    }, [handleLineSize]);

    return (
        <section className={`text-dark py-32 ${className}`}
                 ref={containerRef}
                 {...rest}>
            <Container className="lg:text-center">
                <header className="mb-6"
                        ref={(el) => animateRefs.current[0] = el}
                >
                    <h2 className="text-5xl lg:text-7xl font-medium max-w-[23ch] mx-auto">
                        Seamless user experience on <b className="font-medium text-gradient">your tailor-made
                        platform.</b>
                    </h2>
                </header>

                <p className="text-2xl font-medium max-w-[60ch] mx-auto mb-12"
                   ref={(el) => animateRefs.current[1] = el}
                >
                    Creating AAA video content is only one side of the journey. Developing a smooth user experience
                    ensures high conversion rate, long-term engagement and easy content management for you. Our team
                    will create a platform that your fans will love.
                </p>

                <div>
                    <nav
                        className="relative mb-10"
                        ref={(el) => animateRefs.current[3] = el}
                    >
                        <div
                            className="flex flex-nowrap md:justify-center motion-safe:transition-all motion-safe:duration-500"
                            style={{
                                transform: `translateX(-${navOffset}px)`
                            }}
                        >
                            {slides.map((slide, key) => {
                                return (
                                    <button
                                        className="shrink-0 rounded-none mx-6 bg-transparent text-lg p-2.5 tracking-[0.02em]"
                                        key={`SlideButton: ${key}`}
                                        onClick={() => setActiveTab(key)}
                                        ref={(el) => buttonRefs.current[key] = el}
                                    >
                                        {slide.label}
                                    </button>
                                )
                            })}
                            <div
                                className="absolute bottom-0 h-[2px] bg-gradient-to-r from-gradient-start to-gradient-end motion-safe:transition-all motion-safe:duration-500 will-change-transform"
                                style={lineStyle}/>
                        </div>
                    </nav>
                    <div ref={(el) => animateRefs.current[4] = el}>
                        <Tablet slides={slides} activeTab={activeTab}/>
                    </div>
                </div>
            </Container>
        </section>
    )
}