import Container from "./Container";

import image01 from "../public/images/gallery/01.jpg";
import image02 from "../public/images/gallery/02.jpg";
import image03 from "../public/images/gallery/03.jpg";
import image04 from "../public/images/gallery/04.jpg";
import image05 from "../public/images/gallery/05.jpg";
import image06 from "../public/images/gallery/06.jpg";
import Image from "next/image";
import {useEffect, useRef} from "react";
import {gsap} from "gsap/dist/gsap";

function prepareEl(el, modifier) {
    return {
        element: el,
        modifier: modifier,
        randomness: Math.random() * 2 + 1
    }
}

export default function Gallery({className = '', children, ...rest}) {
    const photoRowRef = useRef(null);
    const photosRef = useRef([]);

    useEffect(() => {
        const photos = photosRef.current;

        function movePhotos(event) {
            if (!photos) return;

            const x = event.clientX;
            const y = event.clientY;
            const winWidth = window.innerWidth;
            const winHeight = window.innerHeight;
            const xOffset = (x / winWidth) * 20;
            const yOffset = (y / winHeight) * 20;

            photos.forEach((photo, index) => {
                const modifier = index % 2 === 0 ? 1 : -1;

                gsap.to(photo.element, {
                    x: xOffset * photo.modifier + photo.randomness,
                    y: yOffset * photo.modifier + photo.randomness,
                    duration: 0.5,
                });
            });
        }


        document.addEventListener('mousemove', movePhotos);

        return () => {
            document.removeEventListener('mousemove', movePhotos);
        };

    }, []);

    return (
        <section className={`text-dark py-32 overflow-hidden ${className}`} {...rest}>
            <Container wide={true}>
                <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-24 lg:min-h-[550px] mb-[130px]"
                     ref={photoRowRef}
                >
                    <div className="relative -mb-[40%] lg:mb-0">
                        <div className="gallery-image lg:absolute top-[10%]"
                             ref={(el) => (photosRef.current[0] = prepareEl(el, 1))}
                             style={{'--delay': '1.2s'}}
                        >
                            <Image src={image01} alt="image01" width={249} height={323} className="animate-float"/>
                        </div>
                        <div className="gallery-image relative lg:absolute -top-1/3 lg:top-1/3 left-1/3"
                             ref={(el) => (photosRef.current[1] = prepareEl(el, -1.5))}
                             style={{'--delay': '0.2s'}}
                        >
                            <Image src={image02} alt="image02" width={267} height={350} className="animate-float"/>
                        </div>
                    </div>

                    <div className="relative -mb-[50%] lg:mb-0">
                        <div className="gallery-image relative lg:absolute top-0 -left-8 lg:left-0"
                             ref={(el) => (photosRef.current[2] = prepareEl(el, 1))}
                        >
                            <Image src={image03} alt="image03" width={410} height={536} className="animate-float"/>
                        </div>

                        <div className="gallery-image relative lg:absolute -top-1/3 lg:top-[56%] left-1/2 lg:left-2/3"
                             ref={(el) => (photosRef.current[3] = prepareEl(el, 2))}
                             style={{'--delay': '0.7s'}}
                        >
                            <Image src={image04} alt="image04" width={220} height={288} className="animate-float"/>
                        </div>
                    </div>

                    <div className="relative mb-[18%]">
                        <div className="gallery-image relative lg:absolute top-0 -left-8 lg:left-[8%]"
                             ref={(el) => (photosRef.current[4] = prepareEl(el, -0.5))}
                             style={{'--delay': '0.5s'}}
                        >
                            <Image src={image05} alt="image05" width={249} height={323} className="animate-float"/>
                        </div>
                        <div className="gallery-image absolute max-w-max top-1/2 lg:top-1/4 right-0"
                             ref={(el) => (photosRef.current[5] = prepareEl(el, -1))}
                             style={{'--delay': '1s'}}
                        >
                            <Image src={image06} alt="image06" width={267} height={350} className="animate-float"/>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}