import {gsap} from "gsap/dist/gsap";
import {useEffect, useRef} from "react";
import Claim from "./Claim";
import Container from "./Container";

export default function MainClaims({className = '', children, ...rest}) {
    const containerRef = useRef(null);

    useEffect(() => {
        const mm = gsap.matchMedia();

        mm.add({
            desktopAndNoReducedMotion: "(min-width: 1024px) and (prefers-reduced-motion: no-preference)",
        }, (context) => {
            // let {desktopAndNoReducedMotion} = context.conditions;

            const claims = gsap.utils.toArray('.claim-wrapper');

            gsap.set(gsap.utils.toArray('.claim-wrapper .claim'), {
                opacity: 0
            });

            claims.forEach((claim) => {
                const title = claim.querySelector('.claim');
                gsap.to(title, {
                    y: -100,
                    scrollTrigger: {
                        trigger: claim,
                        start: 'center center',
                        end: "+=80%",
                        scrub: true,
                        markers: false,
                        pin: title,
                    },
                    onUpdate: function () {
                        const progress = this.progress();
                        title.style.opacity = gsap.utils.interpolate([0, 1, 0], progress).toString();
                    }
                });
            });
        });

        return () => mm.revert();
    }, []);


    return (
        <div ref={containerRef} className="pb-52">
            <Container>
                <div className="claim-wrapper">
                    <Claim>
                        We will produce and launch a high quality video course for you, while <b>you keep 100% of the
                        profit.</b>
                    </Claim>
                </div>

                <div className="claim-wrapper">
                    <Claim>
                        Your course will be available on a tailor-made platform developed <b>specifically for your
                        needs.</b>
                    </Claim>
                </div>

                <div className="claim-wrapper">
                    <Claim className="flex flex-col">
                        <span>One-time payment,</span>
                        <span className="claim-fadeout">No fees, percentages, commisions, bonuses, ...</span>
                        <b>No bullshit.</b>
                    </Claim>
                </div>

                <div className="claim-wrapper">
                    <Claim>
                        We wouldn’t offer it to you, if it wasn’t something that we love and do for ourselves.
                        Check out our love project <a href="https://www.amos.academy">Amos</a>.
                    </Claim>
                </div>
            </Container>
        </div>
    )
}